import { inject } from "mobx-react";
import classnames from "classnames";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import type { BlockFragment, PodcastFragment } from "@ihr-radioedit/inferno-webapi";
import { Container, Grid, Heading, ImageTile } from "../../ui";
import { GridColumnValues } from "../../ui/Grid.component";
import { generatePodcastProps } from "./Podcasts.component";
import { PodcastEpisode } from "./PodcastEpisode.component";
import "./PodcastsCollection.style.scss";

interface PodcastCollectionProps {
  block: BlockFragment;
  podcasts: PodcastFragment[];
  columns: GridColumnValues;
  isHorizontalLayout?: boolean;
  store?: Store;
}

export const PodcastsCollection = inject("store")((props: PodcastCollectionProps) => {
  if (!props.store) {
    return null;
  }
  const { store, block } = props;

  useEffect(() => {
    store.storeBlock({ ...block });
  }, [store, block]);

  const { tags } = props.block;

  const classes = classnames("podcast-collection", {
    [`horizontal`]: tags?.includes("display-hints/podcast-feed-horizontal-all"),
    [`small-thumb`]: tags?.includes("display-hints/podcast-small-thumb"),
  });

  const Title = () => {
    let blockTitle = `${store.site.sections.general?.name} Podcasts`;

    if (!!store.microsite) {
      blockTitle = `${store.microsite?.sections.general?.name} Podcasts`;
    }

    if (!!block.value.title) {
      blockTitle = block.value.title;
    }

    return <Heading level={2}>{blockTitle}</Heading>;
  };

  return (
    <>
      <Container className={classes}>
        <Title />
        <Grid columns={props.columns} gap={false}>
          {props.podcasts &&
            props.podcasts.map((show, i) =>
              show.episodes.items.length ? (
                <Fragment key={i}>
                  {!!props.isHorizontalLayout ? (
                    <PodcastEpisode
                      store={store}
                      id={show.id}
                      slug={show.slug}
                      related_id={show.episodes.items.slice()[0]?.id}
                      hasToggle={false}
                      {...generatePodcastProps({
                        host: store.env.IMAGE_HOST,
                        podcast: show,
                        store,
                        translator: useTranslation(),
                      })}
                    />
                  ) : (
                    <ImageTile
                      {...generatePodcastProps({
                        host: store.env.IMAGE_HOST,
                        podcast: show,
                        store,
                        translator: useTranslation(),
                      })}
                    />
                  )}
                </Fragment>
              ) : null,
            )}
        </Grid>
      </Container>
      <hr className="divider" />
    </>
  );
});

export default PodcastsCollection;
